import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    menu: [],
    sliderData: {},
    cachesPages: [
      {
        url: '/',
        name: '首页',
        pageIndex: ''
      }
    ]
  },
  getters: {
    menu(state) {
      return state.menu
    },
    sliderData(state) {
      return state.sliderData
    },
    cachesPages(state) {
      return state.cachesPages
    }
  },
  mutations: {
    updateMenu(state, payload) {
      state.menu = payload
    },
    updateSliderData(state, payload) {
      state.sliderData = payload
    },
    // 添加
    addCachesPages(state, payload) {
      if (
        state.cachesPages.some((item) => {
          return item.url === payload.url
        })
      ) {
        return
      }
      if (state.cachesPages.length >= 14) {
        state.cachesPages.splice(1, 1)
      }
      state.cachesPages.push(payload)
    },
    // 删除
    delCachesPages(state, payload) {
      state.cachesPages.splice(payload, 1)
    }
  },
  actions: {},
  modules: {}
})
