const permission = [
  {
    name: '员工管理',
    url: '/staff'
  },
  {
    name: '角色管理',
    url: '/role'
  },
  {
    name: '资源权限管理',
    url: '/assets'
  }
]
const classify = [
  {
    name: '课程分类',
    url: '/classify/course'
  },
  {
    name: '课程单标签管理',
    url: '/classify/course/tag'
  },
  {
    name: '倾诉师分类',
    url: '/classify/listener'
  },
  {
    name: '测评分类',
    url: '/classify/test'
  },
  {
    name: '咨询师分类',
    url: '/classify/counselor'
  },
  {
    name: '问答分类',
    url: '/classify/qa'
  }
]
const orders = [
  {
    name: '订单列表',
    url: '/order'
  },
  {
    name: '退款审批',
    url: '/refund'
  }
]
const approval = [
  {
    name: '文章审批',
    url: '/approval/title'
  },
  {
    name: '课程审批',
    url: '/approval/course'
  },
  {
    name: '视频审批',
    url: '/approval/video'
  },
  {
    name: '测评审批',
    url: '/approval/evaluation'
  }
]
const course = [
  {
    name: '课程管理',
    url: '/lesson'
  },
  {
    name: '视频管理',
    url: '/video'
  }
]
export default {
  course,
  approval,
  orders,
  classify,
  permission
}
