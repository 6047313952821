export default [
  {
    path: 'role',
    name: 'role',
    component: () => import('@/views/Rights/role'),
    meta: {
      title: '角色管理'
    }
  },
  {
    path: 'staff',
    name: 'staff',
    component: () => import('@/views/Rights/staff'),
    meta: {
      title: '员工管理'
    }
  },
  {
    path: 'ask',
    name: 'ask',
    component: () => import('@/views/ask'),
    meta: {
      title: '问答管理'
    }
  },
  {
    path: 'client',
    component: () => import('@/views/client'),
    meta: {
      title: '客户管理'
    }
  },
  {
    path: 'counselor',
    component: () => import('@/views/counselor'),
    meta: {
      title: '咨询师管理'
    }
  },
  {
    path: 'lesson',
    component: () => import('@/views/course/Lesson.vue'),
    meta: {
      title: '课程管理'
    }
  },
  {
    path: 'video',
    component: () => import('@/views/course/Video.vue'),
    meta: {
      title: '音视频管理'
    }
  },
  {
    path: 'qrcode',
    component: () => import('@/views/qrcode'),
    meta: {
      title: '二维码管理'
    }
  },
  {
    path: 'order',
    component: () => import('@/views/order'),
    meta: {
      title: '订单管理'
    }
  },
  {
    path: 'notice',
    component: () => import('@/views/notice'),
    meta: {
      title: '公告管理'
    }
  },
  {
    path: 'slider',
    component: () => import('@/views/slider'),
    meta: {
      title: '轮播图'
    }
  },
  {
    path: 'essay',
    name: 'essay',
    component: () => import('@/views/essay'),
    meta: {
      title: '文章管理'
    }
    // children: [
    //   {
    //     path: 'addTitle',
    //     name: 'addTitle',
    //     component: () => import('@/views/essay/AddTitle'),
    //     meta: {
    //       title: '添加文章'
    //     }
    //   }
    // ]
  },
  {
    path: 'essay/addTitle',
    name: 'essay',
    component: () => import('@/views/essay/AddTitle'),
    meta: {
      title: '添加文章'
    }
  },
  {
    path: 'staff/add',
    name: 'addStaff',
    component: () => import('@/views/Rights/staff/Add'),
    meta: {
      title: '添加员工'
    }
  },
  {
    path: 'assets',
    name: 'assets',
    component: () => import('@/views/Rights/assets'),
    meta: {
      title: '资源权限'
    }
  },

  {
    path: 'slider/addSlider',
    name: 'addSlider',
    component: () => import('@/views/slider/addSlider'),
    meta: {
      title: '添加轮播图'
    }
  },
  {
    path: 'slider/editSlider',
    name: 'editSlider',
    component: () => import('@/views/slider/editSlider'),
    meta: {
      title: '修改轮播图'
    }
  },
  // 课程管理
  {
    path: 'course/addCourse',
    name: 'addCourse',
    component: () => import('@/views/course/AddCourse'),
    meta: {
      title: '添加课程'
    }
  },
  {
    path: 'course/courseDetail',
    name: 'courseDetail',
    component: () => import('@/views/course/courseDetail'),
    meta: {
      title: '课程编辑'
    }
  },
  // 视频管理
  {
    path: 'course/addVideo',
    name: 'addVideo',
    component: () => import('@/views/course/AddVideo'),
    meta: {
      title: '添加视频'
    }
  },
  {
    path: 'video/videoDetail',
    name: 'videoDetail',
    component: () => import('@/views/course/VideoDetail'),
    meta: {
      title: '视频编辑'
    }
  },
  {
    path: 'reservation',
    name: 'reservation',
    component: () => import('@/views/reservation'),
    meta: {
      title: '预约管理'
    }
  },
  {
    path: 'counselor/addCounselor',
    name: 'addCounselor',
    component: () => import('@/views/counselor/addCounselor'),
    meta: {
      title: ' 添加咨询师'
    }
  },
  {
    path: 'counselor/edit',
    name: 'edit',
    component: () => import('@/views/counselor/editCounselor'),
    meta: {
      title: '编辑咨询师信息'
    }
  },
  {
    path: 'comment',
    name: 'comment',
    component: () => import('@/views/comment'),
    meta: {
      title: '评论管理'
    }
  },
  {
    path: 'title/editTitle',
    name: 'editTitle',
    component: () => import('@/views/essay/editTitle'),
    meta: {
      title: '编辑文章'
    }
  },
  {
    path: 'approval/title',
    name: 'titleapp',
    component: () => import('@/views/approval/title'),
    meta: {
      title: '文章审批'
    }
  },
  {
    path: 'approval/course',
    name: 'courseapp',
    component: () => import('@/views/approval/course'),
    meta: {
      title: '课程审批'
    }
  },
  {
    path: 'approval/video',
    name: 'videoapp',
    component: () => import('@/views/approval/video'),
    meta: {
      title: '视频审批'
    }
  },
  {
    path: 'approval/evaluation',
    name: 'evaluationapp',
    component: () => import('@/views/approval/evaluation'),
    meta: {
      title: '测评审批'
    }
  },
  {
    path: 'order/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/order/orderDetail'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: 'refund',
    name: 'refund',
    component: () => import('@/views/order/refund'),
    meta: {
      title: '退款审批'
    }
  },
  {
    path: 'log',
    name: 'log',
    component: () => import('@/views/log/index.vue'),
    meta: {
      title: '操作日志'
    }
  },
  {
    path: 'classify/qa',
    name: 'qaclass',
    component: () => import('@/views/classify/qa.vue'),
    meta: {
      title: '问答分类'
    }
  },
  {
    path: 'classify/course',
    name: 'courseclass',
    component: () => import('@/views/classify/course.vue'),
    meta: {
      title: '课程分类'
    }
  },
  {
    path: 'classify/test',
    name: 'testclass',
    component: () => import('@/views/classify/test.vue'),
    meta: {
      title: '测评分类'
    }
  },
  {
    path: 'classify/counselor',
    name: 'counselorclass',
    component: () => import('@/views/classify/counselor.vue'),
    meta: {
      title: ' 咨询师分类'
    }
  },
  {
    path: 'goods',
    name: 'goods',
    component: () => import('@/views/goods/index.vue'),
    meta: {
      title: '商品管理'
    }
  },
  {
    path: 'addGoods',
    name: 'addGoods',
    component: () => import('@/views/goods/addGoods.vue'),
    meta: {
      title: ' 添加商品'
    }
  },
  {
    path: 'editGoods',
    name: 'editGoods',
    component: () => import('@/views/goods/editGoods.vue'),
    meta: {
      title: ' 编辑商品'
    }
  },
  {
    path: 'opinion',
    name: 'opinion',
    component: () => import('@/views/opinion/index.vue'),
    meta: {
      title: '意见反馈'
    }
  },
  {
    path: 'guide',
    name: 'guide',
    component: () => import('@/views/guide/index.vue'),
    meta: {
      title: '咨询指南'
    }
  },
  {
    path: 'evaluation',
    name: 'evaluation',
    component: () => import('@/views/evaluation/index.vue'),
    meta: {
      title: '测评管理'
    }
  },
  {
    path: 'evaluation/addEvaluation',
    name: 'addEvaluation',
    component: () => import('@/views/evaluation/addEvaluation.vue'),
    meta: {
      title: '添加测评'
    }
  },
  {
    path: 'evaluation/editEvaluation',
    name: 'editEvaluation',
    component: () => import('@/views/evaluation/editEvaluation.vue'),
    meta: {
      title: '编辑测评'
    }
  },
  {
    path: 'evaluation/detail',
    name: 'detail',
    component: () => import('@/views/evaluation/detail.vue'),
    meta: {
      title: ' 测评用户'
    }
  },
  {
    path: 'evaluation/evaDetail',
    name: 'evaDetail',
    component: () => import('@/views/evaluation/evaDetail.vue'),
    meta: {
      title: '用户测评'
    }
  },
  {
    path: 'cache',
    name: 'cache',
    component: () => import('@/views/cache'),
    meta: {
      title: '缓存管理'
    }
  },
  {
    path: 'listener',
    name: '倾诉师',
    component: () => import('@/views/listener'),
    meta: {
      title: '倾诉师'
    }
  },
  {
    path: 'listener/addListener',
    name: '添加倾诉师',
    component: () => import('@/views/listener/addListener'),
    meta: {
      title: '添加倾诉师'
    }
  },
  {
    path: 'listener/editListener',
    name: '编辑倾诉师',
    component: () => import('@/views/listener/editListener'),
    meta: {
      title: '编辑倾诉师'
    }
  },
  {
    path: 'classify/listener',
    name: '倾诉师分类',
    component: () => import('@/views/classify/listener'),
    meta: {
      title: '倾诉师分类'
    }
  },
  {
    path: 'listener/comment',
    name: '倾诉评价',
    component: () => import('@/views/listener/comment'),
    meta: {
      title: '倾诉评价'
    }
  },
  {
    path: 'listener/specialty',
    name: '专业列表',
    component: () => import('@/views/listener/specialty'),
    meta: {
      title: '专业列表'
    }
  },
  {
    path: 'listener/tags',
    name: '评价标签',
    component: () => import('@/views/listener/tags'),
    meta: {
      title: '评价标签'
    }
  },
  {
    path: 'listener/reservation',
    name: '预约倾诉',
    component: () => import('@/views/listener/reservation'),
    meta: {
      title: '预约倾诉'
    }
  },
  {
    path: 'classify/course/tag',
    name: '课程标签',
    component: () => import('@/views/classify/courseTag'),
    meta: {
      title: '课程标签'
    }
  },
  {
    path: '/combination/price',
    name: '套餐价格',
    component: () => import('@/views/combination/price'),
    meta: {
      title: '套餐价格'
    }
  },
  {
    path: '/combination/tags',
    name: '套餐标签',
    component: () => import('@/views/combination/tags'),
    meta: {
      title: '套餐标签'
    }
  },
  {
    path: '/combination/menu',
    name: '套餐等级',
    component: () => import('@/views/combination/menu'),
    meta: {
      title: '套餐等级'
    }
  },
  {
    path: '/combination/directory',
    name: '套餐目录',
    component: () => import('@/views/combination/directory'),
    meta: {
      title: '套餐目录'
    }
  },
  {
    path: '/combination/reservation',
    name: '套餐预约',
    component: () => import('@/views/combination/reservation'),
    meta: {
      title: '套餐预约'
    }
  },
  {
    path: '/callback',
    name: '回调管理',
    component: () => import('@/views/callback/index'),
    meta: {
      title: '回调管理'
    }
  }
]
