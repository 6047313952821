<template>
  <el-container>
    <el-aside>
      <logo1></logo1>
      <menu1></menu1>
    </el-aside>
    <el-container>
      <el-header>
        <!-- <div class="left">
          <crumb></crumb> -->
        <bread></bread>
        <!-- </div> -->
        <avatar></avatar>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Menu1 from './Menu1.vue'
import Bread from './Bread.vue'
import Avatar from './Avatar'
import Logo1 from './Logo'

export default {
  name: 'layout',
  data() {
    return {
      breadData: {}
    }
  },
  components: {
    Bread,
    Menu1,
    Logo1,
    Avatar
  }
}
</script>

<style lang="scss">
.el-tooltip__popper {
  max-width: 14%;
}
.flex {
  display: flex;
  align-items: center;
  .info {
    font-size: 14px;
    font-weight: 700;
    padding: 0 4px;
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
    '微软雅黑', Arial, sans-serif;
}
</style>

<style scoped>
.el-container {
  height: 100%;
  background-color: #f6f7f7;
}
::v-deep .el-input {
  max-width: 500px;
}
::v-deep .el-tree-node__label {
  font-size: 12px !important;
  font-weight: 700 !important;
}
::v-deep .el-aside {
  width: 260px !important;
  background-color: #21262a;
  position: relative;
}

.title {
  width: 260px;
  height: 100%;
  line-height: 60px;
  text-align: center;
  color: blue;
  font-size: 18px;
}
.menu1 {
  margin-top: 160px;
}
::v-deep .el-main {
  padding: 10px 10px !important;
  position: relative;
  background-color: #f6f7f7;
}
::v-deep .el-header {
  height: 100px !important;
  display: flex;
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.el-aside {
  scrollbar-width: none;
}
.el-aside::-webkit-scrollbar {
  display: none;
}
::v-deep .el-tree > .el-tree-node {
  border: 1px solid #999;
}
::v-deep .el-form-item__label {
  font-size: 20px;
  font-weight: 800;
}
::v-deep .el-dialog__title {
  font-weight: 800;
  font-size: 20px;
}
::v-deep .el-pagination {
  margin-top: 20px;
}
::v-deep .el-dialog__body {
  max-height: 80vh;
  overflow: auto;
}
.left {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: fit-content;
  justify-content: space-evenly;
}
</style>
